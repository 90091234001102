<template>
  <div id="media">
    <app-navbar></app-navbar>
    <div class="yellowTwoBallon">
      <img v-parallax="0.2" class="img-fluid" src="@/assets/yellowTwoBallon.png" alt="yellowBallone">
    </div>
    <div class="yellowBrickBallone">
      <img v-parallax="0.1" class="img-fluid" src="@/assets/yellowBrickBallone.png" alt="whiteBallone">
    </div>
    <div class="container pb-lg-6 media-block">
      <div class="col-lg-5 offset-lg-3">
        <h3 class="text-center padding-top">{{ "Media.download" | staticTranslation }}</h3>
        <a class="text-center" href="/media/Maraton-hudby-Brno-logo.pdf" download="logo.png"><img src="@/assets/MHB-logo-černé.png" width="60%" alt="logo_festival"></a>
        <h3 class="pt-lg-4 text-center">{{ "Media.pressRelease.label" | staticTranslation }}</h3>
        <div class="pb-lg-4 text-center">
          <h3 class="pt-3">2024</h3>
          <a class="report" href="https://drive.google.com/drive/folders/1-5UpYWn3UyxJ0onQ_MPVmbTzC3AVDA11">6.8.2024: Maraton hudby: Festival plný hvězd a objevů</a>
          <h3 class="pt-3">2023</h3>
          <a class="report" href="https://drive.google.com/drive/folders/1IVRkHUCdC2Fw-I2lcVEOufhDG6-28R4e">23.11.2023: Nová tvář Maratonu hudby Brno</a>
<!--          <a class="report" href="https://drive.google.com/drive/folders/1IMpuyjju_eTDyrLZ63GWe7qYM4Q674aX">TZ č. 3: Maraton hudby Brno 2023 začíná pozítří</a>-->

          <!--          <a class="report" href="https://drive.google.com/drive/u/0/folders/13sQRGKC7w2ao5WK9zZdqgVBrjm8rNbaP" >Fotografie k tiskové zprávě</a>-->
<!--          <a class="report" href="/media/tz_mhb_posila_pozdrav_z_kuchyne.docx" download="">Tisková zpráva 28. 4. 2020</a>-->
<!--          <a class="report" href="/media/TZ_Maraton_hudby_Brno_18_02_2020.docx" download="">Tisková zpráva 18. 2. 2020</a>-->
        </div>
<!--        <div class="pb-lg-4 text-center">-->
<!--          <h3>2019</h3>-->
<!--          <a class="report" href="/media/TIC-TZ-MaratonHudby_Hopkins_LaPutyka.docx" download="">Tisková zpráva 10. 6. 2019</a>-->
<!--          <a class="report" href="/media/TIC-TZ-MaratonHudby_vstupte_zdarma.docx" download="">Tisková zpráva 8. 7. 2019</a>-->
<!--          <a class="report" href="/media/TIC-TZ_MaratonHudby_na-22-místech.docx" download="">Tisková zpráva 31. 7. 2019</a>-->
<!--        </div>-->
      </div>
    </div>
    <app-footer class=""></app-footer>
  </div>
</template>

<script>
import footer from "@/components/base/footer";
import navbar from "@/components/base/navbar";
export default {
  name: "media",
  components: {
    'app-navbar': navbar,
    'app-footer': footer
  }
}
</script>

<style scoped>
h1 {
  font-size: 2.5em;
  padding-top: 3.5em;
  padding-bottom: 3.5em;
  font-family: "Transcript Pro";
}
h2 {
  color: black;
  padding-bottom: 1em;
  font-size: 2em;
  font-family: "Transcript Pro"
}
.padding-top {
  padding-top: 8rem;
}
h3 {
  color: #eae7d6;
  font-family: "Transcript Pro";
}
a {
  font-size: 1.3em;
  display: block;
  color: black;
  text-decoration: none;
  font-family: "Transcript Mono";
}
.report {
  padding-top: 4px;
  padding-bottom: 3px;
  border: solid 2px;
  margin-bottom: 1em;
  font-family: "Transcript Pro";

}
a:hover {
  color: black;
  text-decoration: underline;
  font-family: "Transcript Pro";
}
#media {
  background-color: #ED5B2D;
  min-height: 100vh;
}
.media-block {
  padding-bottom: 4em;
  min-height: 130vh;
}
.yellowTwoBallon {
  width:11%;
  position: absolute;
  top: 18%;
  right: 2%;
  text-align: end;
}
.yellowBrickBallone {
  width:28%;
  position: absolute;
  top: 70%;
  left: -3%;
  text-align: left;
}
@media (max-width: 400px) {
  .yellowBrickBallone {
    display: none;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 970px;
  }
}
</style>
